<template>
  <div>
    <!-- 主体 -->
    <LiefengContent>
      <template v-slot:title>{{ "菜单管理" }}</template>
      <template v-slot:toolsbarLeft>
        <LiefengTree
            ref="mychild"
            treeID="mychild"
            fixedContentID="contentarea"
            :fixedmenu="true"
            @handleData="getList"
            style="width: 200px"
        ></LiefengTree>
      </template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <Button type="success" @click="resetForm" icon="ios-refresh" style="margin-right: 10px">重置</Button>
          <Button
              icon="ios-add"
              type="primary"
              @click="addFormSame"
              style="margin-right: 10px"
          >新增同级
          </Button>
          <Button
              icon="ios-add"
              type="primary"
              @click="addFormSon"
          >新增子级
          </Button>
        </Form>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
            :talbeColumns="pageForm.talbeColumns"
            :tableData="pageForm.tableData"
            :loading="controlForm.loading"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.pageSize"
            @hadlePageSize="changeTableSize"
        ></LiefengTable>
        <FormAdd ref="FormAdd" @resetBtn="resetForm"></FormAdd>
        <FormEdit ref="FormEdit" @resetBtn="resetForm"></FormEdit>

        <!-- 授权子平台 -->
      <LiefengModal
        title="授权子平台"
          :value=" warrantStatus"
          @input="chanegWarrantStatus"
          :fullscreen="true"
          >
        <template v-slot:contentarea>
          <div class="container-div" v-if="warrantStatus">
            <div class="center-table">
                <div style="margin-bottom: 10px; display: flex">
                  <Input
                    v-model="terraceKey"
                    @on-search="getTerraceList"
                    search
                    enter-button="查询"
                    style="width: 560px; margin-right: 10px"
                    placeholder="通过姓名查询"
                  ></Input>
                  <Button type="success" icon="ios-refresh" @click="terraceReset"
                    >重置</Button
                  >
                </div>
                <div class="table">
                  <LiefengTable
                    :tableData="terraceData"
                    :talbeColumns="terraceColumns"
                    :loading="terraceLoading"
                    :pageSize="terracePageSize"
                    :pagesizeOpts="[20, 30, 50, 100, 200]"
                    :total="terraceTotal"
                    :height="tableHeight"
                    @tableSelect="tableSelect"
                    :curPage="terracePage"
                    @hadlePageSize="terraceHadlePageSize"
                  ></LiefengTable>
                </div>
            </div>
            <div class="right-table">
               <div class="right-center">
                  <Button
                    type="primary"
                    style="margin-bottom: 14px"
                    @click="moveUp"
                    >上移</Button
                  >
                  <Button
                    type="primary"
                    style="margin-bottom: 14px"
                    @click="moveDown"
                    >下移</Button
                  >
                  <Button
                    type="error"
                    style="margin-bottom: 14px"
                    @click="delRightList"
                    >删除</Button
                  >
                  <Button type="warning" @click="clearRightList">清空</Button>
                </div>


                <div class="right-right">
                  <div
                    style="
                      text-align: center;
                      font-size: 14px;
                      font-weight: bold;
                      line-height: 40px;
                      color: #515a6e;
                      background: #f8f8f9;
                    "
                  >
                    已选择
                  </div>
                  <List border size="small">
                    <ListItem v-for="(item, index) in rightList" :key="item.id"
                      ><div
                        style="width: 100%; display: block"
                        @click="selectRightList($event, index)"
                      >
                        {{ item.name }}
                      </div></ListItem
                    >
                  </List>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin-right:10px" type="primary" @click="chanegWarrantStatus(false)">确定</Button>
          <Button type="info" @click="chanegWarrantStatus(false)">取消</Button>
        </template>
        
      </LiefengModal>

      </template>
      
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import FormAdd from './childrens/add'
import FormEdit from './childrens/edit'
import LiefengTree from "./tree/LiefengTree";

export default {
  components: {LiefengContent, LiefengTable, FormAdd, FormEdit, LiefengTree,LiefengModal},
  data() {
    return {
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            title: "菜单名称",
            key: "name",
            minWidth: 200,
            align: "center"
          },
          {
            title: "菜单编码",
            key: "code",
            minWidth: 200,
            align: "center"
          },
          {
            title: "菜单链接",
            key: "url",
            minWidth: 200,
            align: "center"
          },
          {
            title: "排序",
            key: "seq",
            minWidth: 200,
            align: "center"
          },
          {
            title: "状态",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              return h("div", {}, params.row.enableStatus == 1 ? "启用" : "未启用")
            }
          },
          {
            title: "菜单图标",
            minWidth: 200,
            align: "center",
            render: (h, params) => {
              if (!params.row.icon) {
                return h("div", {}, "无")
              }
              return h("div",[h("Avatar",
                  {
                    props:{
                      src: params.row.icon
                    }
                  })])
            }
          },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 180,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                // h( 
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //           this.warrantSon(params.row)
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "授权子平台"
                                // ),
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.getDetail(params.row.sysModuleId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.deleteRow(params.row.sysModuleId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      //控制的一些属性
      controlForm:{
        loading:false,
      },
      //查询参数
      searchForm: {
        keyword: '',
        parentId: -1
      },

      // 授权框状态
      warrantStatus:false,
      terraceKey:'',
      // 子平台表格
      tableHeight: "",
      terraceData:[],
      terraceColumns:[
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "序号",
          key: "name",
          width: 100,
          align: "center"
        },
        {
          title: "子平台名称",
          key: "oemName",
          minWidth: 200,
          align: "center"
        },
        {
          title: "子平台编码",
          key: "oemCode",
          minWidth: 100,
          align: "center"
        },
      ],
      terraceLoading:false,
      terracePageSize:20,
      terraceTotal:0,
      terracePage:1,
      // 选中列表
      rightList:[]

    }
  },
  //create函数,刚加载页面时调用
  async created() {
    await this.selectPage()
    this.tableHeight = String(window.innerHeight - 210);
    this.resetGetList()
    this.getTerraceList()
  },
  methods: {
    /*----- 分页事件部分 -------*/

    backClick(){
      this.$router.push('/authCloudSystem')
    },

    //树
    getList(data) {
      if (!!data && !!data.parentId) {
        this.searchForm.parentId = data.parentId;
        this.searchForm['parentParentId'] = data.parentParentId;
      }
      this.pageForm.page = 1;
      this.pageForm.pageSize = 20;
      this.selectPage();
    },


    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize

      this.selectPage()
    },
    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },
    resetForm() {
      this.searchForm = {
        parentId: -1
      }
      this.resetGetList();
      this.pageForm.page = 1
      this.pageForm.pageSize = 20
      this.selectPage()
    },
    //重置按钮
    resetBtn() {
      this.searchForm.keyword = ''
      this.pageForm.page = 1
      this.pageForm.pageSize = 20
      this.selectPage()
    },

    resetGetList() {
      var self = this;
      var v = [{
        keyword: '',
        parentId: -1
      }];
      self.$refs.mychild.getTree(v);
    },
    /*----- modal部分 -------*/
    //新增同级按钮
    addFormSame() {
      if (this.searchForm.parentParentId) {
        this.$refs.FormAdd.formData.parentId = this.searchForm.parentParentId
        this.$refs.FormAdd.titleGrade = '新增同级菜单'
        this.$refs.FormAdd.controlForm.formFlag = true;
      } else {
        this.$refs.FormAdd.formData.parentId = '-1'
        this.$refs.FormAdd.titleGrade = '新增菜单'
        this.$refs.FormAdd.controlForm.formFlag = true;
      }

    },
    //新增子级按钮
    addFormSon() {
      if (this.searchForm.parentId != -1) {
        this.$refs.FormAdd.formData.parentId = this.searchForm.parentId
        this.$refs.FormAdd.titleGrade = '新增子级菜单'
        this.$refs.FormAdd.controlForm.formFlag = true;
      } else {
        this.$Message.warning({
          content: '请选择菜单',
          background: true
        })
      }
    },
    // 方法部分

    /*----- 接口部分 -------*/

    async selectPage() {
      this.controlForm.loading = true;
      console.log(this.searchForm)
      await this.$get('/auth/api/auth/pc/systemModule/searchModuleListPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        ...this.searchForm,
        systemId:this.$route.query.systemId
      }).then(res => {
        this.controlForm.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList
          this.pageForm.total = res.maxCount
          this.pageForm.page = res.currentPage
          this.pageForm.pageSize = res.pageSize
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 获取详情接口
    getDetail(sysModuleId){
      this.$refs.FormEdit.getDetail(sysModuleId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },
    async deleteRow(systemModuleId) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对菜单进行删除操作，请确认是否有子级，是否继续？",
        onOk: () => {
          this.$post('/auth/api/auth/pc/systemModule/delete',{
            systemModuleId
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.resetBtn();
              this.resetGetList()
            }else{
              this.$Message.error({
                content:'删除失败',
                background:true
              })
              return
            }
          })
        },
      });
    },
    
    chanegWarrantStatus(val){
      this.warrantStatus = val
    },

    // 授权子平台
    warrantSon(data){
      this.warrantStatus = true
      console.log(data)
    },

    // 重置
    terraceReset(){

    },

    // 子平台列表
    getTerraceList(){
      this.terraceLoading = true
      this.$get("/auth/api/auth/pc/TenantApi/selectByPage",{
        page:this.terracePage,
        pageSize:this.terracePageSize,
        keyword:this.terraceKey
      }).then( res => {
        this.terraceLoading = false
        if(res.code == 200){
          this.terraceData = res.dataList
          this.terraceTotal = res.maxCount;
          this.terracePageSize = res.pageSize;
          this.terracePage = res.currentPage;
        }else {
          this.$Message.error({
            content:res.desc,
            background:true
          })
        }
      })
    },

    // 表格选中
    tableSelect(val){
      
    },

    // 分页
    terraceHadlePageSize(val){
      this.terracePage = val.page    
      this.terracePageSize = val.pageSize
      this.getTerraceList()
    },

    // 上移
    moveUp(){

    },
    // 下移
    moveDown(){

    },

    // 删除
    delRightList(){

    },
    // 清空
    

  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}
.container-div{
  height: 100%;
  display: flex;
  width: 100%;
  .tree-div{
    width: 200px;
    height: 100%;
    /deep/.ivu-tree {
      height: calc(100vh - 155px);
      overflow: auto;
    }
  }
  .center-table{
    width: calc(100% - 400px);
    margin-left: 20px;
    // border: 1px solid red;
  }
  .right-table{
    margin-left: auto;
    width:410px;
    height: calc(100vh - 127px);
    display: flex;
    .right-center{
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .right-right{
      width: 300px;
      border: 1px solid #dcdee2; 
      overflow: auto
    }
  }
}
</style>